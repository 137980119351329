import React from "react"
import "./src/styles/bootstrap.css"
import "./src/styles/bootstrap-responsive.css"
import "./src/styles/screen.css"

import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"

export const wrapRootElement = ({ element }) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.RECAPTCHA_KEY}>
      {element}
    </GoogleReCaptchaProvider>
  )
}